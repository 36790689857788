import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 640.000000 640.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)" >
<path d="M3146 6167 c-22 -12 -53 -39 -70 -60 -26 -32 -31 -48 -34 -106 -3
-54 0 -76 17 -108 77 -151 279 -159 372 -16 28 44 30 53 26 116 -6 85 -40 141
-107 175 -61 30 -150 30 -204 -1z m205 -74 c67 -67 55 -192 -23 -238 -38 -22
-115 -22 -156 -1 -37 19 -72 81 -72 127 0 18 9 52 20 75 27 56 67 76 143 71
49 -3 63 -8 88 -34z"/>
<path d="M2852 6170 c-18 -4 -34 -9 -36 -11 -2 -2 -16 -60 -32 -128 -17 -69
-35 -126 -41 -128 -6 -2 -43 46 -84 107 l-73 111 -35 -6 c-62 -11 -72 -16 -67
-33 3 -9 14 -71 26 -137 42 -236 39 -225 65 -225 13 0 26 4 29 8 3 5 -8 78
-23 163 -16 85 -27 156 -25 158 1 2 34 -45 71 -103 l68 -106 48 3 c26 2 50 7
53 10 5 4 29 97 59 225 7 30 25 18 25 -16 0 -23 40 -272 46 -280 1 -2 13 0 28
4 17 4 26 13 26 25 0 25 -60 351 -66 361 -6 9 -17 9 -62 -2z"/>
<path d="M3565 6138 c-2 -12 -18 -99 -35 -192 -31 -177 -30 -185 12 -186 12 0
19 15 28 58 16 84 14 80 60 72 22 -4 43 -10 46 -12 3 -3 18 -36 33 -73 30 -70
48 -89 81 -83 18 3 17 7 -10 68 -36 81 -37 86 -2 93 70 14 111 102 76 166 -28
52 -61 70 -152 86 -44 8 -92 17 -106 21 -21 5 -26 2 -31 -18z m150 -64 c64
-10 85 -28 85 -73 0 -56 -16 -65 -106 -57 -42 3 -80 9 -86 12 -6 4 -7 25 -3
53 11 77 14 83 43 77 15 -3 45 -8 67 -12z"/>
<path d="M3972 6013 c-44 -119 -102 -296 -102 -307 0 -13 255 -105 264 -95 3
3 7 16 8 29 3 21 -6 26 -92 55 -92 31 -95 33 -94 61 1 16 7 41 13 56 l10 27
76 -26 c80 -27 105 -25 105 6 0 15 -17 25 -81 46 -88 30 -87 28 -59 100 9 25
37 33 56 17 12 -11 163 -57 168 -51 2 2 6 15 8 28 3 22 -5 27 -112 63 -63 21
-122 41 -131 43 -12 4 -21 -8 -37 -52z"/>
<path d="M2213 6017 l-63 -21 0 -45 c0 -73 22 -345 29 -353 4 -4 17 -5 30 -1
21 5 22 10 19 61 l-3 55 85 29 85 29 22 -26 c12 -14 26 -32 31 -41 7 -12 17
-15 40 -9 l31 6 -26 42 c-88 141 -197 297 -206 296 -7 0 -40 -10 -74 -22z
m105 -116 c23 -36 41 -69 39 -74 -3 -10 -129 -59 -135 -52 -3 2 -7 44 -10 94
-4 81 -3 89 14 94 37 10 49 1 92 -62z"/>
<path d="M1875 5871 c-71 -38 -131 -75 -132 -82 -2 -8 1 -21 7 -29 8 -13 16
-11 59 13 27 15 54 27 60 27 6 0 24 -26 39 -57 15 -32 29 -60 32 -63 3 -3 23
-42 45 -87 22 -45 45 -83 51 -83 5 0 18 6 27 13 15 11 9 26 -59 160 -42 82
-74 150 -72 152 2 1 27 14 56 29 28 14 52 29 52 32 0 11 -21 44 -28 44 -4 -1
-65 -32 -137 -69z"/>
<path d="M4352 5893 c-34 -17 -62 -58 -62 -91 0 -25 36 -76 93 -133 66 -66 62
-119 -9 -119 -50 0 -97 38 -108 87 -7 28 -17 45 -33 52 -30 14 -38 -3 -29 -57
18 -115 185 -185 264 -112 53 50 41 100 -43 190 -70 74 -82 110 -44 130 43 23
129 -20 129 -65 0 -21 32 -55 52 -55 5 0 8 20 8 44 0 54 -34 100 -95 127 -49
23 -80 23 -123 2z"/>
<path d="M4634 5653 c-80 -111 -103 -157 -104 -196 -1 -113 149 -196 246 -137
28 18 167 199 181 237 6 15 -14 43 -31 43 -6 0 -46 -50 -90 -110 -43 -61 -87
-115 -97 -120 -59 -32 -143 17 -143 83 0 29 15 58 70 137 38 55 74 107 79 116
7 11 5 19 -9 30 -11 8 -23 14 -26 14 -4 0 -38 -44 -76 -97z"/>
<path d="M1550 5680 c-30 -22 -56 -44 -58 -48 -2 -8 86 -245 125 -334 18 -42
20 -43 46 -24 17 13 17 17 3 51 -9 21 -16 45 -16 54 1 14 127 111 145 111 4 0
24 -14 46 -30 33 -27 40 -29 58 -18 12 7 21 17 20 23 -1 12 -293 255 -305 255
-5 0 -34 -19 -64 -40z m126 -82 c35 -28 64 -56 64 -62 -1 -13 -93 -81 -111
-81 -7 0 -21 20 -30 45 -9 25 -23 60 -30 78 -13 31 -12 34 11 52 14 11 27 20
29 20 2 0 32 -23 67 -52z"/>
<path d="M4974 5408 c-44 -46 -104 -109 -134 -141 l-54 -58 75 -70 c130 -123
173 -137 236 -74 31 31 35 40 31 75 -3 38 -2 40 25 40 42 0 67 17 83 57 23 54
6 89 -88 177 -44 42 -83 76 -87 76 -4 0 -43 -37 -87 -82z m206 -129 c0 -24
-27 -49 -53 -49 -20 0 -127 91 -127 108 0 3 14 21 32 39 l32 33 58 -57 c32
-32 58 -65 58 -74z m-166 -40 c64 -59 74 -81 52 -114 -28 -43 -72 -34 -135 29
l-54 54 32 41 c18 23 37 41 41 41 5 0 34 -23 64 -51z"/>
<path d="M1293 5451 c-47 -12 -189 -130 -181 -151 6 -14 256 -270 264 -270 5
0 39 29 75 64 92 90 112 167 67 256 -41 80 -137 123 -225 101z m149 -100 c33
-35 38 -46 38 -89 0 -41 -6 -57 -34 -94 -55 -72 -58 -72 -165 37 l-94 95 34
36 c46 49 84 65 139 60 36 -3 51 -11 82 -45z"/>
<path d="M5500 4777 c-52 -26 -64 -90 -24 -131 46 -45 120 -27 139 34 10 31
-12 86 -41 99 -31 14 -43 14 -74 -2z"/>
<path d="M828 4753 c-49 -31 -48 -107 1 -136 42 -24 68 -21 102 12 38 38 38
74 0 112 -33 33 -63 36 -103 12z"/>
<path d="M1720 4026 c0 -610 2 -746 13 -755 8 -6 17 -8 20 -4 4 3 7 2 7 -3 0
-8 420 -10 485 -2 26 3 88 -12 95 -23 3 -4 12 -4 20 1 13 8 12 10 -2 10 -10 0
-18 7 -18 16 0 14 2 14 22 0 14 -10 42 -16 73 -15 37 0 45 3 30 9 -19 8 -19 9
3 9 12 1 22 -3 22 -9 0 -16 27 -11 34 6 3 9 6 131 6 272 1 293 11 354 72 439
41 58 117 117 178 139 48 18 345 32 395 20 34 -9 33 -41 -2 -63 -165 -102
-193 -170 -193 -468 0 -111 3 -236 6 -278 l7 -77 56 0 c31 0 72 -5 91 -10 41
-13 102 -14 171 -5 45 6 49 5 49 -14 0 -15 6 -21 23 -21 26 0 57 56 57 104 0
27 -2 28 -44 25 -24 -2 -42 -1 -39 4 2 4 -14 7 -37 7 -77 0 -127 60 -110 134
12 52 115 233 252 439 32 49 58 90 58 92 0 2 14 25 31 50 70 104 155 112 215
21 91 -137 288 -456 323 -522 46 -86 50 -115 22 -160 -26 -43 -76 -64 -154
-64 l-66 0 4 -27 c2 -16 4 -53 5 -83 2 -47 4 -51 10 -25 4 17 8 24 9 18 1 -10
56 -13 241 -13 153 0 240 -4 240 -10 0 -5 16 -10 35 -10 19 0 35 5 35 10 0 6
36 10 89 10 62 0 91 4 95 13 3 6 3 2 1 -9 -4 -14 0 -24 10 -28 12 -4 15 3 17
37 l1 42 6 -45 c11 -79 21 98 21 371 0 336 -27 481 -123 659 -148 276 -409
463 -721 519 -40 7 -409 11 -1103 11 l-1043 0 0 -744z m697 -762 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M457 4200 c-25 -20 -40 -53 -71 -161 -26 -92 -25 -94 52 -113 37 -10
112 -31 167 -47 55 -17 108 -33 117 -35 14 -4 21 9 38 68 12 40 26 86 31 102
23 73 4 136 -46 161 -44 20 -77 19 -108 -5 -30 -24 -37 -25 -37 -5 0 51 -94
73 -143 35z m105 -65 c12 -26 3 -83 -21 -147 -10 -27 -11 -27 -53 -14 -42 12
-43 14 -41 52 1 21 11 60 22 87 18 41 24 47 51 47 23 0 33 -6 42 -25z m156
-21 c27 -18 27 -39 5 -126 -11 -42 -23 -68 -31 -68 -33 0 -102 24 -102 35 0
27 41 145 56 160 20 19 44 19 72 -1z"/>
<path d="M5736 4099 c-88 -22 -161 -41 -163 -43 -6 -6 35 -162 51 -194 33 -65
134 -112 208 -98 19 3 55 20 78 37 76 55 97 146 63 275 -16 61 -18 64 -48 63
-16 0 -102 -18 -189 -40z m178 -38 c4 -5 9 -36 12 -68 5 -45 2 -67 -10 -92
-45 -85 -172 -96 -229 -19 -18 24 -49 118 -41 127 5 5 217 58 241 60 12 0 24
-3 27 -8z"/>
<path d="M337 3823 c-4 -3 -7 -17 -7 -29 0 -19 7 -24 43 -28 230 -31 241 -34
264 -61 46 -52 19 -145 -45 -160 -16 -3 -84 1 -152 10 -69 8 -128 15 -132 15
-4 0 -8 -13 -8 -29 l0 -29 103 -13 c201 -27 256 -16 291 57 28 57 30 109 7
156 -33 67 -53 76 -213 98 -167 23 -143 21 -151 13z"/>
<path d="M5650 3692 c0 -32 17 -42 77 -42 31 0 32 -1 39 -57 4 -32 8 -75 8
-96 1 -37 -1 -40 -44 -56 -41 -15 -45 -19 -42 -46 2 -17 5 -31 7 -33 2 -2 79
28 172 66 92 38 176 72 187 75 16 6 17 15 11 80 -8 81 -20 97 -74 97 -20 0
-79 6 -131 14 -52 8 -121 17 -152 21 l-58 6 0 -29z m280 -68 l71 -7 -3 -35
c-3 -34 -6 -37 -76 -65 -40 -16 -76 -27 -78 -24 -8 8 -23 130 -17 139 3 5 11
7 18 4 7 -2 45 -8 85 -12z"/>
<path d="M530 3403 c-38 -26 -47 -42 -69 -122 -30 -111 -67 -139 -111 -83 -30
38 -23 107 14 137 25 20 35 58 18 69 -16 9 -73 -37 -87 -71 -41 -99 2 -206 89
-220 65 -10 101 31 126 144 15 67 34 93 69 93 38 0 53 -21 59 -80 4 -47 2 -52
-32 -84 -23 -23 -36 -44 -36 -60 0 -30 -2 -29 36 -16 66 23 104 131 75 216
-26 76 -100 113 -151 77z"/>
<path d="M6025 3372 c-3 -3 -5 -32 -5 -64 l0 -58 -170 0 -170 0 0 -30 c0 -30
1 -30 53 -30 28 0 103 -3 165 -7 l112 -6 0 -64 c0 -63 0 -63 28 -63 l29 0 6
98 c4 53 7 125 7 158 0 58 -2 62 -25 66 -13 3 -27 3 -30 0z"/>
<path d="M1732 3208 c-9 -9 -12 -188 -12 -710 l0 -699 1068 4 c1054 3 1068 3
1147 24 289 77 525 262 657 514 83 158 108 268 115 505 6 224 24 201 -151 203
-63 1 -123 4 -133 5 -10 2 -90 7 -178 10 -88 4 -203 9 -256 12 l-97 6 -4 -184
c-4 -182 -4 -183 -35 -243 -40 -77 -112 -147 -188 -182 -59 -28 -63 -28 -250
-28 -183 0 -190 1 -193 20 -2 15 11 29 54 55 61 37 102 86 140 165 22 47 24
61 24 232 l0 181 -87 7 c-102 8 -104 8 -121 6 -7 0 -23 -25 -36 -53 -40 -93
-315 -530 -367 -585 -33 -36 -76 -43 -122 -22 -14 6 -60 64 -105 133 -219 331
-312 497 -312 559 0 21 -4 36 -9 33 -5 -3 -63 -2 -129 4 -67 5 -181 13 -254
16 -73 3 -138 10 -144 15 -6 5 -15 4 -22 -3z"/>
<path d="M3240 3181 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M5680 3060 c0 -11 -1 -23 -1 -27 -1 -5 19 -18 45 -31 35 -17 46 -27
42 -40 -2 -9 -10 -49 -16 -89 -12 -77 -18 -83 -86 -83 -24 0 -33 -5 -38 -22
-11 -42 -7 -47 27 -43 17 2 95 9 172 15 216 17 200 11 214 77 20 103 31 91
-167 182 -98 44 -182 81 -185 81 -4 0 -7 -9 -7 -20z m256 -156 c51 -24 55 -28
49 -53 -3 -14 -9 -29 -13 -32 -10 -9 -172 -18 -172 -10 0 15 22 126 26 133 5
8 32 -1 110 -38z"/>
<path d="M640 3028 c0 -24 5 -72 10 -108 5 -35 7 -66 4 -69 -2 -3 -26 -7 -54
-11 l-49 -6 -11 70 c-6 39 -12 78 -13 86 -2 16 -57 27 -57 11 0 -5 5 -44 11
-87 12 -88 9 -94 -57 -94 -47 0 -45 -3 -60 143 -7 66 -7 67 -35 67 -35 0 -35
-5 -11 -165 l17 -110 35 1 c19 0 104 9 188 19 108 13 155 22 159 32 3 7 0 57
-6 111 -18 148 -19 152 -47 152 -22 0 -24 -4 -24 -42z"/>
<path d="M707 2708 c-41 -52 -74 -88 -81 -88 -4 0 -19 11 -33 25 -73 67 -187
26 -200 -72 -5 -38 38 -218 55 -234 4 -4 88 17 187 45 160 46 180 54 183 74 5
32 -18 36 -84 17 -64 -18 -69 -16 -80 42 -5 29 0 40 45 92 50 57 61 90 42 119
-6 10 -15 5 -34 -20z m-147 -110 c21 -23 41 -83 44 -132 1 -25 -4 -30 -44 -42
-71 -22 -74 -20 -94 45 -23 77 -19 122 14 138 36 18 56 16 80 -9z"/>
<path d="M5609 2664 c-5 -15 -8 -28 -7 -29 9 -9 220 -79 276 -92 46 -11 19
-21 -75 -27 -148 -10 -148 -10 -168 -63 l-18 -46 34 -30 c96 -85 161 -147 155
-147 -4 0 -74 23 -154 50 -136 46 -148 49 -159 33 -12 -16 -18 -43 -9 -43 2 0
83 -27 179 -60 97 -33 180 -60 185 -60 5 0 15 21 23 47 l13 47 -99 91 c-54 50
-95 96 -92 102 4 6 65 14 136 18 l130 7 17 50 c9 27 14 51 12 53 -4 4 -357
125 -365 125 -2 0 -8 -12 -14 -26z"/>
<path d="M710 2321 c-73 -23 -128 -70 -154 -135 -31 -74 -11 -152 57 -223 116
-121 321 -52 352 118 8 43 -21 133 -56 173 -45 52 -142 85 -199 67z m128 -81
c35 -21 71 -87 72 -128 0 -65 -68 -137 -143 -149 -114 -18 -209 129 -145 223
48 70 148 95 216 54z"/>
<path d="M5435 2143 c-132 -69 -164 -246 -62 -344 35 -34 98 -59 151 -59 119
0 209 94 208 219 -1 85 -48 154 -129 188 -43 18 -131 16 -168 -4z m160 -66
c27 -15 47 -37 63 -69 20 -41 22 -51 12 -87 -31 -116 -150 -157 -240 -83 -35
29 -60 76 -60 115 0 13 10 43 23 67 41 77 124 101 202 57z"/>
<path d="M887 1859 l-157 -112 20 -31 c31 -51 38 -52 174 -24 70 13 129 21
132 17 2 -4 -25 -58 -62 -120 l-67 -112 18 -34 c10 -18 24 -38 30 -43 9 -7 58
23 169 101 86 61 156 114 156 118 0 3 -7 14 -15 25 -15 20 1 29 -200 -110 -27
-19 -56 -42 -62 -51 -7 -9 -16 -14 -19 -10 -4 3 24 56 61 117 73 121 71 110
21 174 -14 18 -18 18 -123 -4 -59 -12 -119 -24 -133 -27 -23 -3 -23 -3 -7 9
10 7 72 51 138 96 65 46 119 87 119 91 0 5 -8 15 -19 25 -17 16 -26 11 -174
-95z"/>
<path d="M5197 1806 c-15 -23 -14 -25 33 -62 27 -22 49 -44 50 -50 0 -6 -10
-23 -21 -38 -21 -26 -24 -27 -111 -25 -85 3 -91 2 -108 -22 l-19 -24 37 -7
c20 -4 58 -7 83 -7 l46 -1 -1 -61 c-1 -58 1 -63 33 -90 77 -64 144 -42 240 79
33 42 61 82 60 87 0 9 -258 216 -294 237 -8 5 -19 -1 -28 -16z m196 -192 c26
-20 47 -39 47 -43 0 -3 -24 -32 -53 -65 -59 -66 -96 -77 -129 -36 -29 36 -28
43 42 144 14 19 30 36 36 36 6 0 32 -16 57 -36z"/>
<path d="M1278 1493 c-31 -54 -75 -133 -98 -176 l-43 -79 59 -53 58 -54 36 21
c34 21 181 113 267 168 l42 27 -20 19 c-20 18 -22 18 -68 -7 l-46 -26 -68 63
-67 63 22 46 c22 46 19 73 -9 82 -6 2 -35 -40 -65 -94z m82 -142 l53 -48 -69
-47 c-38 -25 -74 -46 -80 -46 -6 0 -19 9 -30 21 -18 20 -18 22 21 95 22 41 42
74 45 74 4 0 30 -22 60 -49z"/>
<path d="M4958 1533 c-2 -5 -44 -47 -93 -95 -90 -85 -90 -86 -73 -108 l18 -22
72 68 c40 38 76 73 80 79 8 11 78 -52 78 -71 0 -7 -25 -36 -55 -65 -58 -55
-64 -69 -40 -89 12 -10 26 -2 78 44 35 31 68 56 73 55 5 0 22 -16 39 -36 l29
-36 -77 -72 c-42 -40 -77 -77 -77 -81 0 -5 10 -15 21 -23 20 -12 28 -7 116 81
51 51 92 98 91 104 -4 17 -253 274 -265 274 -6 0 -13 -3 -15 -7z"/>
<path d="M4645 1269 c-85 -45 -121 -107 -103 -176 17 -66 81 -79 198 -42 87
28 120 22 120 -24 -1 -56 -44 -90 -109 -86 -23 2 -48 -3 -62 -13 l-24 -15 32
-17 c42 -21 85 -20 131 3 61 31 85 64 89 123 4 46 1 56 -22 81 -32 33 -77 36
-160 7 -66 -23 -120 -26 -131 -8 -39 62 69 153 142 119 19 -8 29 -8 44 1 30
19 24 34 -20 52 -53 21 -77 20 -125 -5z"/>
<path d="M1630 1208 c-110 -160 -131 -188 -141 -188 -6 0 -31 14 -56 31 -45
31 -45 31 -64 12 -19 -18 -19 -20 -2 -35 39 -34 237 -168 249 -168 7 0 16 9
19 21 7 21 -2 32 -57 66 -16 10 -28 23 -28 30 0 12 58 98 142 209 21 28 38 54
38 59 0 9 -31 34 -42 35 -4 0 -30 -33 -58 -72z"/>
<path d="M1815 1168 c-23 -71 -95 -364 -91 -373 5 -15 114 -76 130 -74 6 1 72
65 146 141 l135 139 -23 19 -23 19 -40 -40 -40 -41 -32 22 c-18 11 -54 32 -80
46 -35 18 -47 30 -43 40 3 9 9 33 12 54 5 32 3 40 -14 49 -26 14 -32 14 -37
-1z m80 -209 c30 -17 58 -35 61 -41 6 -9 -103 -128 -117 -128 -4 0 -17 8 -30
19 l-23 18 24 82 c12 44 24 81 27 81 2 0 28 -14 58 -31z"/>
<path d="M4309 1067 c-58 -31 -89 -77 -89 -134 0 -34 14 -72 63 -168 34 -69
67 -125 73 -125 5 0 18 6 27 13 15 11 11 23 -43 129 -39 77 -60 129 -60 152 0
49 34 86 86 93 58 8 88 -23 154 -156 30 -61 59 -111 65 -111 6 0 19 6 30 14
17 12 15 19 -40 122 -74 139 -87 158 -129 178 -47 22 -85 20 -137 -7z"/>
<path d="M3998 926 c-100 -38 -131 -56 -154 -91 -33 -50 8 -143 64 -148 12 -1
22 -2 22 -3 0 -1 -6 -17 -14 -35 -25 -64 18 -129 88 -129 22 0 222 62 232 72
1 2 -12 46 -30 98 -19 52 -47 133 -62 180 -18 53 -35 85 -44 86 -8 1 -54 -13
-102 -30z m95 -93 c10 -26 14 -50 9 -55 -13 -13 -123 -48 -152 -48 -35 0 -50
17 -50 56 0 38 12 49 81 73 84 30 92 28 112 -26z m47 -138 c27 -72 28 -71 -48
-96 -76 -24 -117 -21 -127 11 -11 36 4 57 58 78 100 39 105 39 117 7z"/>
<path d="M2160 924 c-60 -33 -92 -82 -98 -149 -6 -73 11 -117 64 -164 45 -40
181 -99 194 -84 9 11 140 339 140 351 0 6 -36 24 -80 41 -99 39 -158 40 -220
5z m171 -51 c27 -10 49 -21 49 -26 0 -26 -96 -247 -107 -247 -26 0 -113 50
-128 74 -58 88 6 215 109 216 15 0 50 -8 77 -17z"/>
<path d="M3064 566 c-39 -33 -39 -89 0 -122 34 -30 73 -30 108 -1 41 34 41 86
2 122 -34 32 -75 32 -110 1z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
